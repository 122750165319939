.offcanvas {
  &.offcanvas-filter-sidebar,
  &.offcanvas-contact-info {
    @include media-breakpoint-up(xl) {
      z-index: 0;
      position: sticky;
      top: $top-nav-height;
      height: calc(100vh - #{$top-nav-height});
      visibility: visible;
      background-color: transparent;
      box-shadow: none;
    }
    &.offcanvas-contact-info{
      @include media-breakpoint-down(xl){
        .dark &{
          background-color: $card-bg-dark;
        }
      }
      @include media-breakpoint-up(xl){
        position: static;
      }
      border: 0;
      height: auto;
    }
    &.show{
      .ticket-todo-list{
        height: auto !important;
      }
    }
    &.offcanvas-start,
    &.offcanvas-end{
      @include media-breakpoint-up(xl) {
        border-right: 0px;
        transform: translateX(0%);
      }
    }
    .form-check-input[type=checkbox],
    .form-check-input[type=radio] {
      background-color: var(--#{$prefix}card-bg);

      &:checked {
        background-color: var(--#{$prefix}primary);
      }
    }
  }
}