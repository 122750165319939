/*-----------------------------------------------
|   Scrollbar style for windows
-----------------------------------------------*/
.scrollbar {
  @include chrome-scrollbar;
  &.scrollbar-none-xl{
    @include media-breakpoint-up(xl){
      overflow: initial;
    }
  }
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

.overflow-y-hidden {
  overflow-y: hidden !important;
}

.navbar-vibrant {
  .scrollbar {
    @include chrome-scrollbar(rgba($white, 0.4));
  }
}

.firefox {
  .scrollbar {
    @include firefox-scrollbar;
    &.scrollbar-none-xl{
      @include media-breakpoint-up(xl){
        overflow: initial !important;
      }
    }
  }
  .overflow-x-hidden {
    overflow-x: hidden !important;
  }
  
  .overflow-y-hidden {
    overflow-y: hidden !important;
  }

  .navbar-vibrant {
    .scrollbar {
      @include firefox-scrollbar(rgba($white, 0.4));
    }
  }
}

.os-theme-dark {
  &>.os-scrollbar {
    &>.os-scrollbar-track>.os-scrollbar-handle {
      background: $scrollbar-bg;
    }

    @include hover-focus {
      &>.os-scrollbar-track>.os-scrollbar-handle {
        background: $scrollbar-bg;
      }
    }
  }
}

/*-----------------------------------------------
|   Simplebar
-----------------------------------------------*/
.simplebar-scrollbar {
  &::before {
    background-color: $simplebar-bg;
  }
}

.simplebar-content-wrapper {
  &::-webkit-scrollbar {
    display: none;
    width: 0;
    height: 0;
  }
}